import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import ContactPerson from "../components/contactPerson";
import JobItem from "../components/jobItem";
import { GatsbyImage } from "gatsby-plugin-image";
// svgs

import deliver from "../assets/images/doodle-deliver.svg";

const Jobs = ({ data }) => {
  return (
    <Layout lang="de" langPath="nav.jobs.link">
      <Seo
        title="Bei Monday findest Du den Job, der zu dir passt"
        description="Bei Monday erwarten Dich mehr als nur Nullen und Einsen: ein starkes Team von Experten, modernste Entwickler-Tools und entspannte Chefs."
        keywords="Jobs, Stellenausschreibung, Bewerbung, Java, Entwickler, Developer, Software, Frontend, CoreMedia, Consultant, IT, Projektmanagement, Systemadministrator, Controlling"
      />
      <Hero
        headline="Dein neuer Job bei Monday"
        subline="Bei uns bekommt Deine kreative Exzellenz Platz, sich zu entfalten. Höchste Zeit also, dass Du Dich bewirbst."
        section="Jobs"
      >
        <GatsbyImage
          image={data.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Jobs"
        />
      </Hero>
      <div className="container mb-4 mb-md-6">
        <div className="row">
          <div className="col-12 col-md-5 bg-size-contain bg-shape-cross mb-3 mb-md-0">
            <img
              className="w-100 mx-auto"
              src={deliver}
              alt="Wir bieten die Werkzeuge für Ihre CMS-Plattform"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2>Mehr als Nullen und Einsen</h2>
            <p>
              Bei Monday arbeitest Du mit modernsten Entwickler-Tools, sowie mit
              erfahrenen Kollegen und entspannten Chefs an Deiner Seite.
              <br />
              Du kannst Dich mit Deinem Wissen einbringen, Verantwortung
              übernehmen und Dich stetig weiterentwickeln.
            </p>
            <p>
              Denn für uns zählt nicht nur die Zufriedenheit unserer Kunden,
              sondern die des ganzen Teams. Willkommen!
            </p>
            <Link
              to="/kultur"
              className="btn bg-monday-skyblue text-color-white text-color-hover-monday-skyblue"
            >
              Mehr zu unserer Kultur
            </Link>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row mb-3 reveal">
          <h2 className="col-12 text-md-center text-size-3 text-size-md-2">
            Aktuelle Stellenangebote
          </h2>
        </div>
        <div className="row jobs">
          {data.jobs.edges.map(({ node }) => (
            <JobItem
              key={node.id}
              headline={node.frontmatter.headline}
              paragraph={node.frontmatter.teaser}
              linkTo={node.frontmatter.slug}
            />
          ))}
        </div>
      </div>
      <ContactPerson
        headline="Wir freuen uns, von Dir zu hören."
        text="Du kannst was? Du bist sympathisch? Du kannst mit Kunden? Perfekt. Dann sollten wir uns kennenlernen. Denn auf diese Dinge kommt es in unserem Team an."
        contactEmail="jobs@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Jannik Bischof, Leiter Recruiting"
      >
        <GatsbyImage
          image={data.jannik.childImageSharp.gatsbyImageData}
          alt="Jannik Bischof"
        />
      </ContactPerson>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    jobs: allMdx(
      sort: { fields: frontmatter___priority, order: ASC }
      filter: {
        fields: { collection: { eq: "jobs" }, lang: { eq: "de" } }
        frontmatter: { inactive: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            headline
            slug
            teaser
          }
        }
      }
    }
    hero: file(relativePath: { eq: "hero-jobs.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    jannik: file(relativePath: { eq: "profiles/jannik-bischof.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`;

export default Jobs;
